var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dashboard-editor-container" },
    [
      _c(
        "el-row",
        {
          staticStyle: {
            background: "#fff",
            padding: "16px 16px 0",
            "margin-bottom": "32px"
          }
        },
        [_c("line-chart", { attrs: { "chart-data": _vm.lineChartData } })],
        1
      ),
      _c(
        "el-row",
        {
          staticStyle: {
            background: "#fff",
            padding: "16px 16px 0",
            "margin-bottom": "32px"
          }
        },
        [_c("line-chart", { attrs: { "chart-data": _vm.lineChartData2 } })],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }